<template>
  <div class="pro-list">
    <Empty photo="3" label="暂无产品信息" v-if="list.length == 0"/>
    <div class="pro-card" v-for="item in list" :key="item.id">
      <div class="photo">
        <img :src="item.photo" class="contain-img" alt="">
      </div>
      <div class="info">
        <div class="title van-ellipsis">{{ item.category }}</div>
        <div class="desc van-ellipsis">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { getMyProList } from '@/api/common'
  import Empty from '@/components/empty'
  
  export default {
    components: {
      Empty
    },
    data() {
      return {
        list: []
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
         const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getMyProList({is_buy: 1}).then(res => {
          this.list = res.data.list
          toast.clear()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  .pro-list {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    padding: 5.3333vw 4vw;
    background: #F4F4F4;
  }
  .pro-card {
    height: 22.9333vw;
    border-radius: 1.6vw;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    &+.pro-card {
      margin-top: 4vw;
    }
    .photo {
      width: 14.9333vw;
      height: 14.9333vw;
      border-radius: 1.0667vw;
      background: #F4F4F4;
      overflow: hidden;
    }
    .info {
      flex: 1;
      width: 0;
      margin-left: 5.3333vw;
    }
    .title {
      font-size: 4vw;
      color: #1C1F20;
      line-height: 1.1;
    }
    .desc {
      font-size: 3.2vw;
      color: #4C4C57;
      line-height: 1.1;
      margin-top: 1.6vw
    }
  }
</style>